//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  setup () {
    function openUsercentrics () {
      if (!UC_UI) { return }
      UC_UI.showSecondLayer()
    }

    return {
      openUsercentrics
    }
  }
})
